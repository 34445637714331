<template>
  <v-card
    elevation="0"
    :max-height="maxHeight"
    class="campaign-plan d-flex flex-column scrollable"
  >
    <v-progress-linear
      :active="loading"
      indeterminate
      absolute
      top
      height="2"
      color="primary"
      class="loading faded"
    />
    <v-card-title 
      class="text-overline text-subtitle-1 grey--text px-7 pb-2"
    >
      Plano de Inserção
    </v-card-title>

    <div class="brief px-6 mb-3 d-flex align-center text-body-2">
      <v-tooltip 
        top
        open-delay="250"
        transition="fade-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            large
            plain
            class="item px-2 mr-4"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon left>
              {{ icons.impressions }}
            </v-icon>
            <span class="text">{{ campaign.impressions | numeral('0,0') }}</span>
          </v-btn>
        </template>
        <span class="hint">Impressões Contratadas</span>
      </v-tooltip>
      <v-tooltip 
        top
        open-delay="250"
        transition="fade-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn 
            text
            large
            plain
            class="item px-2 mr-4"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon left>
              {{ icons.budget }}
            </v-icon>
            <span class="text">{{ campaign.budget | numeral('$ 0,0') }}</span>
          </v-btn>
        </template>
        <span class="hint">Orçamento Contratado</span>
      </v-tooltip>
      <v-tooltip 
        top
        open-delay="250"
        transition="fade-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn 
            text
            large
            plain
            class="item text-capitalize px-2"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon left>
              {{ icons.calendar }}
            </v-icon>
            <span class="text">{{ campaign.period | formatPeriod }}</span>
          </v-btn>
        </template>
        <span class="hint">Período de Duração</span>
      </v-tooltip>
    </div>

    <div
      class="campaign-ads px-4 pb-2"
    >
      <v-divider class="mx-3" />
      <v-list
        flat
      >
        <v-subheader class="overline px-3 mb-n2">
          Anúncios 
          <v-badge 
            inline
            bordered
            color="grey lighten-1"
            :content="adCount==0&&loading ? 'Carregando...' : adCount"
            class="counter grey--text ml-1"
          />
        </v-subheader>
        
        <v-sheet
          v-for="ad in ads"
          :key="'ad'+ad.id"
          class="ad-plan px-3 py-4 rounded"
        >
          <header class="ad-header mb-4">
            <media-player
              :url="ad.media.url"
              :title="ad.title"
              rounded
              class="mb-4"
            />
            <p class="ad-title font-weight-bold text-overline text-caption d-inline-block mb-0">
              {{ ad.title }}
            </p>
          </header>
         
          <v-list-item class="sub-item pl-4">
            <v-list-item-icon class="mr-4">
              <v-icon color="primary">{{ icons.location }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-subtitle class="primary--text small text-overline my-2">
                Geolocalização
              </v-list-item-subtitle>
              <v-list-item-title class="text-subtitle-2 untruncate">
                {{ ad.location | locationBrief(zones, geofences) }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item class="sub-item pl-4">
            <v-list-item-icon class="mr-4">
              <v-icon color="primary">{{ icons.calendar }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-subtitle class="primary--text small text-overline my-2">
                Período
              </v-list-item-subtitle>
              <v-list-item-title class="text-subtitle-2 text-capitalize untruncate">
                {{ ad.period | dateBrief }}
                <br>
                {{ ad.recurrence | hoursBrief }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item 
            v-if="ad.vehicles!=null&&ad.vehicles!=0"
            class="sub-item pl-4"
          >
            <v-list-item-icon class="mr-4">
              <v-icon color="primary">{{ icons.vehicles }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-subtitle class="primary--text small text-overline my-2">
                Categorias de Veículo
              </v-list-item-subtitle>
              <v-list-item-title class="text-subtitle-2 untruncate">
                {{ ad.vehicles | vehiclesBrief }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item 
            v-if="ad.temperature!=null&&ad.temperature!=''"
            class="sub-item pl-4"
          >
            <v-list-item-icon class="mr-4">
              <v-icon color="primary">{{ icons.weather }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-subtitle class="primary--text small text-overline my-2">
                Temperatura
              </v-list-item-subtitle>
              <v-list-item-title class="text-subtitle-2 untruncate">
                {{ ad.temperature | weatherBrief }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-sheet>
      </v-list>
    </div>
  </v-card>
</template>

<style>

  .campaign-plan .child {
    min-height: 48px !important;
  }

  .campaign-plan .counter .v-badge__badge {
    background: transparent !important;
    color: inherit !important;
  }
  .campaign-plan .counter .v-badge__badge::after {
    border: 1px solid var(--light-border) !important;
  }

  .card-title {
    font-size: 1.125rem !important;
  }
  .card-title {
    color: var(--mobees-black);
    opacity: .6;
  }

  .campaign-plan .ad-title {
    line-height: 1.4;
  }

  .campaign-plan .small {
    font-size: .75em !important;
  }

  /* .campaign-plan .v-list-group .v-list-group__header .v-list-item__icon.v-list-group__header__append-icon {
    min-width: 24px;
  }

  .campaign-plan .sub-item .v-list-group__header {
    padding-left: 16px;
  }
  .campaign-plan .sub-item .v-list-group__header .v-list-group__header__prepend-icon {
    display: none;
  }

  .campaign-plan .active-item, .campaign-plan .sub-item {
    border-left: 2px solid var(--city-blue-A400);
  } */
  
</style>

<script>
  import services from '@/services'
  import { mdiChevronDown, mdiChevronUp, mdiEyeOffOutline, mdiPauseCircleOutline, mdiImage, mdiCurrencyUsd, mdiCalendarRange, mdiMapMarker, mdiWeatherSunny, mdiCarMultiple } from '@mdi/js';
  import _ from 'lodash';
  import numeral from 'numeral'
  var moment = require('moment');

  export default {
    props: {
      campaign: {
        type: Object,
        default: () => null
      },
      status: {
        type: Object,
        default: () => {
          return {}
        }
      },
      maxHeight: {
        type: String,
        default: '80vh'
      },
      mini: {
        type: Boolean,
        default: false
      },
      toggleable: {
        type: Boolean,
        default: false
      },
      loading: {
        type: Boolean,
        default: false
      },
      children: {
        type: Object,
        default: () => null
      },
      cities: {
        type: Object,
        default: () => {}
      },
      admin: {
        type: Boolean,
        default: true
      },
    },

    data: () => ({
      icons: {
        mdiChevronDown,
        mdiChevronUp,
        mdiEyeOffOutline,
        mdiPauseCircleOutline,
        impressions: mdiImage, 
        budget: mdiCurrencyUsd, 
        calendar: mdiCalendarRange,
        location: mdiMapMarker,
        weather: mdiWeatherSunny,
        vehicles: mdiCarMultiple,
      },
      details: null,
      segments: {},
    }),

    computed: {
      hasChildren () {
        const children = this.children;
        return _.size(children)>0;
      },
      ads () {
        const campaign = this.campaign;
        const children = this.children;
        return this.hasChildren ? _.reduce(_.clone(children), (ads, child) => {
          return _.assign({}, ads, child.ads);
        }, {}) : campaign.ads;
      },
      hasAds () {
        return this.adCount>0;
      },
      adCount () {
        return _.size(this.ads);
      },
      paid () {
        return _.some(this.children, c => c.paid) && _.size(this.children)>1;
      },
      zones () {
        return _.mapValues(_.groupBy(this.geofences, 'zone.id'), (zone, z) => {
          const g = _.find(zone, ['zone.id', parseInt(z)]);
          return {
            id: g.zone.id,
            title: g.zone.title,
            geofences: zone
          }
        });
      },

      geofences () {
        const cities = this.cities;
        return _.reduce(cities, (geofences, city) => {
          _.each(city.zones, zone => {
            _.each(zone.geofences, g => {
              geofences[g.id] = {
                id: g.id,
                title: g.title,
                url: g.url,
                zone: { id: zone.id, title: zone.title, url: zone.url },
                city: { id: city.id, title: city.title, url: city.url },
                state: _.clone(city.state),
                country: _.clone(city.country),
              }
            });
          })
          return geofences;
        }, {});
      },
    },

    methods: {
      ...services,

      toggle (b) {
        if (this.toggleable){
          this.$emit('toggle', {
            toggle: b, 
            view: 'plan'
          });
        }
      },

      updateChildren () {
        this.$emit('update-children', _.map(_.keys(_.pickBy(this.segments, s => s==true)), id => parseInt(id)));
      },

      hasChildAds (ads) {
        return _.size(ads) > 0;
      }
    },

    watch: {
      campaign: {
        immediate: true,
        deep: true,
        handler (v) {
        }
      },
    },
    
    filters: {
      formatPeriod (period) {
        const p = period;
        let format = 'DD/MMM';
        if (moment(p.start).month() == moment(p.end).month()) format = 'DD';
        const start = moment(p.start).format(format).toString();
        const end = moment(p.end).format('DD/MMM').toString();
        return start + ' – ' + end;
      },

      locationBrief (data, supportedZones, supportedGeofences) {
        const pois = _.map(data.pois, 'title');
        const selectedGeofences = _.reject(_.map(_.size(data.geofences) == 0 ? _.values(supportedGeofences) : data.geofences, id => {
            return _.find(supportedGeofences, ['id', id])
          }), g => _.isNil(g));
        const zones = _.filter(_.map(_.groupBy(selectedGeofences, 'zone.id'), (zone, z) => {
          return _.has(supportedZones, z) && supportedZones[z].geofences.length == zone.length ? supportedZones[parseInt(z)].title : '';
        }), z => z != '');
        const geofences = _.map(_.filter(selectedGeofences, g => {
          return _.indexOf(zones, g.zone.title)<0;
        }), 'title').sort();
        const all = _.concat(pois, zones, geofences);
        const brief = _.reduce(all, (brief, item, i) => {
          return brief==null ? item : brief+(i==all.length-1 ? ' e ' : ', ')+item;
        }, null);
        return brief==null ? 'Rio de Janeiro' : brief;
      },

      vehiclesBrief (data) {
        const options = {
          2: 'Apenas para Taxis',
          1: 'Carros de Aplicativo',
          0: 'Em qualquer Categoria de Veículo',
        }
        return options[data];
      },

      dateBrief (p) {
        let format = 'DD MMM';
        if (moment(p.start).month() == moment(p.end).month()) format = 'DD';
        const start = _.replace(moment(p.start).format(format).toString(), ' ', '/');
        const end = _.replace(moment(p.end).format('DD MMM').toString(), ' ', '/');
        return start + ' – ' + end;
      },

      hoursBrief (data) {
        const dict = {
          Monday: {
            index: 0,
            text: 'Seg'
          },
          Tuesday: {
            index: 1,
            text: 'Ter',
          },
          Wednesday: {
            index: 2,
            text: 'Qua',
          },
          Thursday: {
            index: 3,
            text: 'Qui',
          },
          Friday: {
            index: 4,
            text: 'Sex',
          },
          Saturday: {
            index: 5,
            text: 'Sáb',
          },
          Sunday: {
            index: 6,
            text: 'Dom',
          }
        };
        const groupBy = /\(\^(.+?)\$\)/g;
        const splitBy = /\(([^)]+)\)/g;
        const groups = _.map([...data.regex.matchAll(groupBy)], g => {
          const group = [...g[1].matchAll(splitBy)];
          const days = _.split(group[0][1], '|');
          const hours = _.map(_.split(group[1][1], '|'), h => parseInt(h)).sort((a, b) => a - b);
          return { days, hours };
        })
        const brief = _.join(_.reduce(groups, (brief, group) => {
          let days = _.sortBy(_.map(group.days, d => dict[d]), ['index']);
          days = _.size(dict)==_.size(days) ? 'Seg a Dom' : days.length==1 ? _.first(days).text : _.join(_.map(days, d => d.text), ', ');
          const hours = group.hours.length==24 ? '24h' : _.first(group.hours) + '–' + (_.last(group.hours)+1) + 'h';
          brief.push(days + ' ' + hours);
          return brief;
        }, []), ' | ');
        return brief;
      },

      weatherBrief (data) {
        let range = '';
        if (!_.isEmpty(data)) {
          const groupBy = /\(([^)]+)\)/g;
          const groups = _.map([...data.matchAll(groupBy)], g => {
            const degrees = _.map(_.split(g[1], '|'), d => parseInt(d)).sort((a, b) => a - b);
            return [_.first(degrees), _.last(degrees)+1];
          })
          range = _.last(groups)
        }
        return range=='' ? 'Qualquer Temperatura' : 'Entre ' + range[0] + '°C e ' + range[1] + '°C';
      },

      count (data) {
        return _.size(data);
      },
      
      visibleText (b) {
        return b ? '' : '(invisível)';
      }
    },

    components: {
      MediaPlayer: () => import('@/components/campaigns/MediaPlayer'),
    }
  }
</script>